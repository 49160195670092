import { isSameDay } from 'date-fns'

const keyName = 'heartin-visitor-session'
class VisitorSession {
  static getValue() {
    const v = localStorage.getItem(keyName)
    if (!v) {
      return {
        count: 1,
        lastAccessDateAt: new Date().toISOString(),
      }
    }
    return JSON.parse(v)
  }
  static setValue(valueJson) {
    localStorage.setItem(keyName, JSON.stringify(valueJson))
  }
  static count() {
    return VisitorSession.getValue().count
  }
  static increment() {
    const value = VisitorSession.getValue()
    // 日をまたいだ訪問数をカウントする
    if (!isSameDay(new Date(value.lastAccessDateAt), new Date())) {
      value.count++
      value.lastAccessDateAt = new Date().toISOString()
    }
    VisitorSession.setValue(value)
  }
}

export { VisitorSession }
