class SnsEmbedding extends HTMLElement {
  async connectedCallback() {
    const url = this.getAttribute('url');
    const response = await fetch(url);
    if (response.ok) {
      this.innerHTML = await response.text();
      this.loadScript();
    }
  }

  loadScript() {
    Array.from(this.querySelectorAll('script')).forEach(element => {
      const script = document.createElement('script');
      Array.from(element.attributes).forEach(attribute => {
        script.setAttribute(attribute.name, attribute.value);
      });
      script.appendChild(document.createTextNode(element.innerHTML));
      element.replaceWith(script);
    });
  }
}

customElements.define('sns-embedding', SnsEmbedding);
