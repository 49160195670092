import Readmore from 'readmore-js'

document.addEventListener('DOMContentLoaded', () => {
  new Readmore('.js-readmore', {
    speed: 200,
    collapsedHeight: 116,
    moreLink:
      '<div class="text-end"><a href="#" class="btn-readmore">MORE</a></div>',
    lessLink:
      '<div class="text-end"><a href="#" class="btn-readmore">CLOSE</a></div>',
  })

  new Readmore('.js-readmore-member', {
    speed: 200,
    collapsedHeight: 0,
    moreLink:
      '<div class="text-end"><a href="#" class="btn-readmore">MORE</a></div>',
    lessLink:
      '<div class="text-end"><a href="#" class="btn-readmore">CLOSE</a></div>',
  })
})
