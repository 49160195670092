import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '../initializers'
import '../lib/imports'
import '../lib/stimulus'
import '../lib/readmore'
import '../lib/registerServiceWorker'
import '../custom_elements'
import 'bootstrap'
import objectFitImages from 'object-fit-images'
import smoothscroll from 'smoothscroll-polyfill'
import 'chartkick/chart.js'

import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-free'
library.add(fas, far, fab)

import Rails from '@rails/ujs'
import { Turbo } from '@hotwired/turbo-rails'
import { VisitorSession } from '../lib/visitorSession'

Turbo.session.drive = false

// NOTE: rails-ujs has already been loaded! を防ぐ
// eslint-disable-next-line no-underscore-dangle
if (!window._rails_loaded) {
  Rails.start()
}

document.addEventListener('DOMContentLoaded', () => {
  objectFitImages()
})


// NOTE: ここを消してしまうとSafariでsmoothスクロールが無効になってしまう
// こんなのもありそう https://github.com/wessberg/scroll-behavior-polyfill
smoothscroll.polyfill()
window.addEventListener('load', () => {
  const anchor = location.hash
  if (anchor.length > 0) {
    const element = document.querySelector(anchor)
    if (element !== null) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }
})

VisitorSession.increment()
